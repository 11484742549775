<template>
  <b-form @submit.prevent='submitForm' class='form-inputs--mobile--lg'>
    <b-card>
      <b-form-row>
        <b-col md='12'>
          <field-address
            title='Indirizzo di residenza'
            field-id='residencialAddress'
            v-model='$v.formInvoicePerson.residencialAddress.$model'
            :state='fieldStateAddressReference'
          />
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md='12'>
          <field-billing
            title='Dati di fatturazione'
            field-id='invoiceReference'
            v-model='$v.formInvoicePerson.invoiceReference.$model'
            :state='fieldStateInvoiceReference'
            is-company
          />
        </b-col>
      </b-form-row>
    </b-card>
  </b-form>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

import lodash from 'lodash';

import {
  checkTaxCode, checkVatNumber, decodeFormFieldError,
  fieldStateAddress, fieldStateInvoice, isNotEmpty, omitNull,
  VALIDATE_ADDRESS,
  validateFormFieldState,
} from '@/utils/validators';
import FieldBilling from '@/components/fields/FieldBilling.vue';
import FieldAddress from '@/components/fields/FieldAddress.vue';

export default {
  name: 'FormInvoicePerson',
  components: { FieldAddress, FieldBilling },
  // emits: ['invoicePersonData'],
  data() {
    return {
      formInvoicePerson: {
        residencialAddress: {
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
      },
      clientValidation: {
        formInvoicePerson: {
          residencialAddress: {
            ...VALIDATE_ADDRESS,
          },
          invoiceReference: {
            taxCode: {
              required,
              checkTaxCode,
            },
            vatNumber: {
              checkVatNumber,
            },
            invoicePecEmail: {
              contactData: {
                email,
              },
            },
          },
        },
      },
      serverValidation: {},
    };
  },
  computed: {
    rules() {
      // When the serverValidation property is set this computed property
      // fires and merges the client and server validation
      return lodash.merge({}, this.serverValidation, this.clientValidation);
    },
    fieldStateAddressReference() {
      return fieldStateAddress(this.$v.formInvoicePerson.residencialAddress);
    },
    fieldStateInvoiceReference() {
      return fieldStateInvoice(this.$v.formInvoicePerson.invoiceReference);
    },
  },
  validations() {
    return this.rules;
  },
  created() {
    this.populateForm();
  },
  watch: {
    formInvoicePerson: {
      handler(newData, oldData) {
        console.log('watch', newData, oldData);
        this.$v.$touch();
        this.$emit('invoice-person-data', {
          invoiceData: newData,
          invalidForm: this.$v.$invalid,
        });
      },
      deep: true,
    },
  },
  methods: {
    validateState: validateFormFieldState,
    decodeError: decodeFormFieldError,
    populateForm() {
      const person = this.$store.getters['subject/currentPerson'];
      const deepcloned = lodash.cloneDeep(person);

      const empty = {
        residencialAddress: {
          countryCode: null,
          streetName: null,
          zip: null,
          city: null,
          province: null,
        },
        invoiceReference: {
          taxCode: null,
          vatNumber: null,
          sdiCode: null,
          invoicePecEmail: {
            type: 'PEC',
            contactData: null,
          },
        },
      };

      if (isNotEmpty(deepcloned)) {
        this.formInvoicePerson = { ...empty, ...omitNull(deepcloned) };
      } else {
        this.formInvoicePerson = { ...empty };
      }
      this.$v.$touch();
    },
    submitForm() {
      return false;
    },
  },
};
</script>

<style scoped>

</style>
